import styled from "styled-components";

import { black, white, yellow } from "theme/colors";
import { h4, p } from "theme/typography";

export const DefaultContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${white.default};
  position: relative;

  padding-top: 109px;

  section {
    display: flex;
    flex-wrap: wrap;
    &.c404 {
      flex-direction: column;
      max-height: 80vh;
      align-items: center;
      img {
        object-fit: contain;
        max-height: 450px;
      }
    }
    &.policy {
      display: block;
      padding-bottom: 40px;
      ul {
        margin-bottom: 1em;
        li {
          ${p}
          margin-bottom: 0.6em;
        }
      }
    }
    .column {
      width: 100%;
      height: auto;
      &.fixed {
        position: relative; /* Required for Safari */
        width: 100%;
        display: flex;
        flex-direction: column;
        top: 0px;
        height: fit-content;
        span {
          font-weight: bold;
        }
      }
      &.circle-box-container {
        width: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .row {
          padding: 20px 0;
          display: flex;
          min-height: 30vh;

          .text-block {
            flex: 1;
            padding-left: 15px;
            h3 {
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 769px) {
    padding-top: 109px;
    section {
      flex-wrap: nowrap;
      .column {
        padding: 20px;
        width: 40%;
        height: 100%;
        &.fixed {
          width: 55%;
          position: -webkit-sticky; /* Required for Safari */
          position: sticky;

          padding-top: 100px;
          padding-bottom: 50px;
          h1 {
            max-width: 80%;
          }
          p {
            max-width: 70%;
          }
        }
        &.circle-box-container {
          width: 60%;
          padding: 120px 0 80px;

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .row {
            padding: unset;
            display: flex;
            min-height: 30vh;

            .text-block {
              flex: 1;
              padding-left: 15px;
              h3 {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
`;

export const DotContainer = styled.span`
  position: relative;
  display: block;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 5px solid ${(props) => (props.color ? props.color : "white")};
  &::after {
    content: "";
    background-color: ${black.default};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 5px;
  }
`;

export const HomeContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: ${black.default};
  .react-player {
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    opacity: 0.5;
    * {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .row {
      width: 100%;
      h1 {
        position: relative;
        color: ${white.default};
        z-index: 12;
      }
    }
  }
`;

export const GetinTouchContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: ${yellow.default};
  display: flex;
  align-items: center;
  padding: 109px 0 50px;
  .container {
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    .info {
      margin-bottom: 60px;
    }
    .form {
      width: 100%;
      div {
        margin-bottom: 60px;
        input,
        textarea {
          ${p}
          background-color: transparent;
          outline: none;
          border: none;
          border-bottom: 2px solid ${black.default};
          width: 100%;
          color: ${black.default};
          padding: 10px;
          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */

            color: ${black.default};
            opacity: 1; /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */

            color: ${black.default};
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */

            color: ${black.default};
          }
        }
        .select {
          display: flex;
          flex-wrap: wrap;
          .select-label {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 20px 10px;
            text-align: center;
            &:last-child {
              margin-right: 0px;
            }
          }
          .checkmark {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;

            border: 2px solid ${black.default};
          }
          .select-label {
            &:hover input ~ .checkmark {
              border: 2px solid ${white.default};
            }
          }
          .select-label input[type="checkbox"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .select-label input {
            &:checked ~ .checkmark {
              border: 2px solid ${white.default};
            }
          }
        }
      }
      .submit-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0;
        button {
          ${h4}
          background-color: ${black.default};
          color: ${white.default};
          padding: 10px 15px;
          text-transform: uppercase;
        }
      }
    }
  }
  @media (min-width: 769px) {
    min-height: 100vh;
    .container {
      display: flex;
      .info {
        width: 30%;
        margin-bottom: 0;
      }
      .form {
        width: 50%;
        margin-top: 0;
        div {
          .select {
            flex-wrap: nowrap;
            .select-label {
              width: 20%;
            }
          }
        }
      }
    }
  }
`;
