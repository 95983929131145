import * as React from "react";
import { Link } from "gatsby";

import CreateHead from "components/createHeader";
import { DefaultContainer } from "components/common/Structure";
import Footer from "components/footer/Footer";

// markup
const NotFoundPage = (props) => {
  const { setColor } = props;
  React.useEffect(() => {
    setColor(true);
  }, []);

  return (
    <>
      <CreateHead title="404 | devstudio" />

      <DefaultContainer>
        <section className="container container--xsmall c404">
          <img src="/404.png" />
          <Link to="/">Return</Link>
        </section>
        <Footer lang="PT" />
      </DefaultContainer>
    </>
  );
};

export default NotFoundPage;
